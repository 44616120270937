$font: #f5f5f5;
$bg: #111111;
$highlight: #F89D12;

// $font: #111111;
// $bg: white;
// $highlight: blue;


html {
    body {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        background-color: $bg;
    }
}

.landing-container {
    background-color: $bg;
    // height: 100vh;
    // margin: 0 12.5%;
    padding: 0;
}

.navbar-container {
    text-align: center;
}

.navbar-inner {
    margin-top: 2rem;
    display: inline-block;
}

.navbar-parent {

    .fab {
        color: $font;
    }
    
    .fas {
        color: $font;
    }
}

.navbar-link {
    font-size: 2rem;
    padding: 1rem;
}

.header-parent {
    color: $font;
    height: 100vh;
    box-sizing: border-box;
    overflow: hidden;
    // text-align: center;
}

.header-parent-inner {
    padding-top: 15%;
    // display: inline-block;
}

// .box1 {
//     background-color: red;
// }

// .box2 {
//     background-color: blue;
// }

.header-content {
    margin: 0;
    color: $font;

    .hello {
        color: $highlight;
        font-size: 6rem;
        font-weight: bolder;
    }

    .line2 {
        font-size: 2.5rem;
        font-weight: bold;
    }
    .line3 {
        margin-top: 0.5rem;
        font-size: 2.5rem;
        font-weight: bold;
    }
}

.header-content-project {
    margin-top: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
}

.link {
    cursor: pointer;
}

.header-image {
    text-align: center;
    .header-image-web {
        img {
            width: 275px;
            height: 275px;
        }
    }
}

.hr-project {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: #f5f5f5;
    margin-bottom: 10rem;
    margin-top: 2rem;
    height: 1px;
    padding: 0;
}


.card-div1 {
    padding: 0 0 5rem 0;
}

.card-div {
    padding: 5rem 0;
}

.card-div2 {
    padding: 5rem 0 8rem 0;
}

// .box3 {
//     background-color:blue;
// }

// .box4 {
//     background-color:red;
// }

// .box5 {
//     background-color:yellow;
// }

.card-img {
    width: 500px;
    height: 500px;
    overflow: hidden;
    text-align: center;

    img {
        max-width: 100%;
        max-height: 100%;
        display: inline-block;
    }

}

.img1 {

}


.img2 {
    margin: 15% 0;

}

.img3 {
    margin: 15% 0;

}

.img4 {
    margin: 15% 0;

}

.card-container {
    padding: 20% 0;
    color: $font;

    a {
        text-decoration: none;
        color: $highlight;
        font-weight: 500;  
    }
 
}

.project-git {
    font-size: 2rem;
}

.project-title {
    padding-top: 0;
    margin-top:0;
    font-size: 2.5rem;
    font-weight: bold;
}

.project-description {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.6rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}


.contact-parent {
color: $font;

    a{
        text-decoration: underline;
        color: $highlight;
    }
}

.contact-line {
    font-size: 2.5rem;
font-weight: 600;
}


.hr-footer {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: #f5f5f5;
    margin-bottom: 1rem;
    margin-top: 5rem;
    height: 1px;
    padding: 0;
}

.footer-container {
    text-align: right;
    color: $font;
    font-weight: 500;
}

@media (max-width: 1279px) {
    
}

@media (max-width: 991.98px) {

    .landing-container {
        margin: 0;
        height: auto;
    }

    .header-parent-inner {
        margin-top: 7%;
    }

    .box2 {
        display: none;
    }
 
    .box4 {
        display: none;
    }

    .card-img-container {
        text-align: center;
       .card-img{
           display: inline-block;
       }
    }


}


@media (max-width: 767.98px) {


        .container{
            max-width: 700px;
        }
    

    .hr-project {
        margin-bottom: 8rem;
    }

    .card-img {
        width: 400px;
        height: 400px;
    
    }

    .card-container {
        padding: 8% 0;
    }


    .card-div1 {
        padding: 0 0 1rem 0;
    }
    
    .card-div {
        padding: 1rem 0;
    }
    
    .card-div2 {
        padding: 1rem 0 5rem 0;
    }

    .contact-line {
        font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.9rem;
    }


}

@media (max-width: 575.98px) {

    .container{
        max-width: 400px;
    }

    .header-parent {
        height: 90vh;
    }

    .header-parent-inner {
        padding-top: 30%;
    }
    
    
    .header-content {
    
        .hello {
            color: $highlight;
            font-size: 7rem;
            font-weight: bolder;
        }
    
        .line2 {
            font-size: 2.5rem;
            font-weight: bold;
        }
        .line3 {
            margin-top: 0.5rem;
            font-size: 2.5rem;
            font-weight: bold;
            margin-bottom: .6rem;
        }
    }

    .hr-project {
        margin-top: 0;
        margin-bottom: 5.5rem;
    }

    .card-div1 {
        padding: 0 0 2.5rem 0;
    }
    
    .card-div {
        padding: 2.5rem 0;
    }
    
    .card-div2 {
        padding: 2.5rem 0 5rem 0;
    }
  
    .card-img {
        width: 340px;
        height: 340px;
        margin-bottom: 0;
        display: flex;
        justify-content: center;
    
        // img {
        //     margin: 28% 0;
        // transform: scale(2.8);
        // }
    }

    .project-description {
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.6rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    .contact-parent {
        color: $font;
        
            a{
                text-decoration: underline;
                color: $highlight;
            }
        }
        
        .contact-line {
            font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.6rem;
        }


}




 


